import { defineStore } from "pinia";
import { getMeetingPackageOptions } from "~/helpers/api/user";
import type { MeetingPackageOption } from "~/model/meetingPackage";
import type { UserType } from "~/model/user";

export const getInitialState = (): MeetingPackageOptionStoreState => ({
  meetingPackageOptions: [],
  isLoading: false,
});

export const useMeetingPackageOptionStore = defineStore(
  "meetingPackageOption",
  {
    state: getInitialState,
    getters: {
      selectMeetingPackageOptionByUserType: (state) => (userType: UserType) => {
        return state.meetingPackageOptions.find(
          (meetingPackageOption) => meetingPackageOption.userType === userType
        );
      },
    },
    actions: {
      async loadMeetingPackageOptions() {
        if (this.meetingPackageOptions.length === 0) {
          this.isLoading = true;
          try {
            this.meetingPackageOptions = await getMeetingPackageOptions();
          } catch (error) {
            console.error(error);
          }

          this.isLoading = false;
        }
      },
      clearStore() {
        this.$reset();
      },
    },
  }
);

export type MeetingPackageOptionStoreState = {
  meetingPackageOptions: MeetingPackageOption[];
  isLoading: boolean;
};
